import { useMemo } from "react";
import { useQueryClient } from "react-query";
import { TFunction } from "i18next";
import { useResetRecoilState } from "recoil";

import {
  MutationSideEffectType,
  useAppMutation,
  useAppQuery,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";

import {
  PagingResponseForBoard,
  ResponseFailureInfo,
} from "../../types/common/common";
import { PartnerListItem } from "../../types/forwarding/partner";

import {
  CHECK_CONTRACTOR_DUPLICATE_REQ,
  CHECK_CONTRACTOR_DUPLICATE_RES,
  CREATE_USER_PARTNER_REQ,
  CREATE_USER_PARTNER_RES,
  DELETE_PARTNER_ITEM_REQ,
  DELETE_PARTNER_MANAGER_REQ,
  DELETE_PARTNER_MANAGER_RES,
  GET_PARTNER_DETAIL_REQ,
  GET_PARTNER_DETAIL_RES,
  GET_USER_PARTNER_LIST_REQ,
  GET_USER_PARTNER_LIST_RES,
  SELECT_QUOTATION_PARTNER_REQ,
  SELECT_QUOTATION_PARTNER_RES,
  UPDATE_PARTNER_DETAIL_REQ,
  UPDATE_PARTNER_DETAIL_RES,
} from "../../api-interfaces/shipda-api/partner";
import { FORWARDING_PARTNER_ATOMS } from "../../states/forwarding/partner";

export const PARTNER_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/PARTNER_QUERY" }] as const,

  getPartnerDetail: (params: GET_PARTNER_DETAIL_REQ) =>
    [
      {
        ...PARTNER_QUERY_KEY_GEN.all()[0],
        entity: "getPartnerDetail",
        ...params,
      },
    ] as const,

  getUserPartnerList: (params: GET_USER_PARTNER_LIST_REQ) =>
    [
      {
        ...PARTNER_QUERY_KEY_GEN.all()[0],
        entity: "getUserPartnerList",
        ...params,
      },
    ] as const,
};

function useGetPartnerList({
  page,
  perPage,
  transportMode,
  businessArea,
  country,
  companyName,
  accountNumber,
  BRNNumber,
  enabled,
  operatorFlag,
  pageFlag,
  region,
}: {
  page: number;
  perPage?: number;
  transportMode?: string;
  businessArea?: string;
  country?: string;
  companyName?: string;
  accountNumber?: string;
  BRNNumber?: string;
  enabled?: boolean;
  operatorFlag?: boolean;
  pageFlag?: boolean;
  region: string;
}) {
  const queryResult = useAppQuery<PagingResponseForBoard<PartnerListItem>>({
    queryKey: [
      "forwarding",
      "partner",
      "list",
      page,
      perPage,
      transportMode,
      businessArea,
      country,
      companyName,
      accountNumber,
      BRNNumber,
      pageFlag,
      operatorFlag,
      region,
    ],
    enabled,
    keepPreviousData: true,
    requestOptions: {
      method: "get",
      path: "/partner",
      apiType: "ShipdaAdminDefaultNew",
      params: {
        page,
        perPage,
        transportMode,
        businessArea,
        country,
        companyName,
        accountNumber,
        BRNNumber,
        pageFlag,
        operatorFlag,
        region,
      },
    },
  });

  const partnerList = useMemo(() => {
    return queryResult.data?.list;
  }, [queryResult.data?.list]);

  return { ...queryResult, partnerList };
}

function useGetPartnerDetail(
  params: GET_PARTNER_DETAIL_REQ & {
    enabled: boolean;
    onSuccess?: (data: GET_PARTNER_DETAIL_RES) => void;
    onError?: (
      error: ResponseFailureInfo | undefined,
      hideFailureModal: () => void
    ) => void;
  }
) {
  const { partnerId, enabled, onSuccess, onError } = params;

  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof PARTNER_QUERY_KEY_GEN.getPartnerDetail>,
    GET_PARTNER_DETAIL_RES
  >({
    queryKey: PARTNER_QUERY_KEY_GEN.getPartnerDetail({ partnerId }),

    requestOptions: {
      method: "get",
      path: `/contractor/${partnerId}`,
      apiType: "ShipdaDefaultNew",
    },
    enabled,
    onError,
    onSuccess,
  });

  return queryResult;
}

function useCreateUserPartner({
  onSuccess,
  onError,
  onSettled,
  hidesFailureModal,
  t,
}: {
  onSuccess?: (res: CREATE_USER_PARTNER_RES) => void;
  onError?: (err: ResponseFailureInfo | undefined) => void;
  onSettled?: () => void;
  hidesFailureModal?: boolean;
  t: TFunction<"partner">;
}) {
  const queryClient = useQueryClient();

  const mutation = useAppMutation<
    CREATE_USER_PARTNER_REQ,
    CREATE_USER_PARTNER_RES
  >({
    requestOptions: {
      method: "post",
      path: `/contractor`,
      apiType: "ShipdaDefaultNew",
    },
    onSuccess: (res) => {
      onSuccess?.(res);

      queryClient.invalidateQueries(PARTNER_QUERY_KEY_GEN.all());
    },
    onError,
    onSettled,
    hidesFailureModal,
    failureModalInfo: {
      customizeMessage: () => {
        return {
          title: t("partner:API_업체등록_오류"),
          messageType: "titleOnly",
        };
      },
    },
  });

  return { ...mutation };
}

function useUpdatePartnerDetail() {
  const queryClient = useQueryClient();

  const resetTempManagerList = useResetRecoilState(
    FORWARDING_PARTNER_ATOMS.PARTNER_DETAIL_TEMP_MANAGER_LIST
  );

  const mutation = useAppMutation<
    UPDATE_PARTNER_DETAIL_REQ,
    UPDATE_PARTNER_DETAIL_RES
  >({
    requestOptions: {
      method: "put",
      path: `/contractor`,
      apiType: "ShipdaDefaultNew",
    },

    onSuccess: () => {
      queryClient.invalidateQueries(PARTNER_QUERY_KEY_GEN.all());
      resetTempManagerList();
    },
  });

  return { ...mutation };
}

function useDeletePartnerManager(t: TFunction<"partner">) {
  const queryClient = useQueryClient();

  const mutation = useAppMutation<
    DELETE_PARTNER_MANAGER_REQ,
    DELETE_PARTNER_MANAGER_RES
  >({
    requestOptions: {
      method: "delete",
      path: "/contractor/manager",
      apiType: "ShipdaDefaultNew",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (!failureInfo) return {};

        /** 저장했던 매니저를 전부 삭제하는 경우 */
        const isLeastOneNeeded = failureInfo.errorCode === "E501";

        const failureCode = failureInfo.code as 404 | 400;

        const title =
          {
            404: t("partner:API_매니저_삭제_찾기_오류"),
            400: t("partner:API_매니저_삭제_처리_오류"),
          }[failureCode] || t("partner:API_매니저_삭제_처리_오류");

        if (isLeastOneNeeded) {
          return {
            title: t("partner:API_매니저_전체_삭제_오류"),
            messageType: "titleOnly",
          };
        }

        return {
          title: title,
          messageType: "titleOnly",
        };
      },
    },

    onSuccess: () => {
      queryClient.invalidateQueries(PARTNER_QUERY_KEY_GEN.all());
    },
  });

  return { ...mutation };
}

/**
 * ResponseHandler를 사용하는 컴포넌트가 두개라 t함수는 optional로 처리
 */
function useGetUserPartnerList({
  enabled,
  onSuccess,
  t,
  hidesLoading,
  ...params
}: {
  enabled?: boolean;
  onSuccess?: (data: GET_USER_PARTNER_LIST_RES) => void;
  t?: TFunction<"partner">;
  hidesLoading?: boolean;
} & GET_USER_PARTNER_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof PARTNER_QUERY_KEY_GEN.getUserPartnerList>,
    GET_USER_PARTNER_LIST_REQ,
    GET_USER_PARTNER_LIST_RES
  >({
    queryKey: PARTNER_QUERY_KEY_GEN.getUserPartnerList(params),
    requestOptions: {
      method: "get",
      path: `/contractor`,
      apiType: "ShipdaDefaultNew",
      params,
    },

    keepPreviousData: true,

    ...(typeof enabled === "boolean" ? { enabled } : {}),

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: t && t("partner:API_업체_조회_오류"),
      }),
    },

    hidesLoading,
  });

  return { ...queryResult };
}

function useDeleteUserPartner(
  { onSuccess, t }: { onSuccess?: () => void; t: TFunction<"partner"> },
  sideEffectOptions?: MutationSideEffectType<DELETE_PARTNER_ITEM_REQ, unknown>
) {
  const mutation = useAppMutation<DELETE_PARTNER_ITEM_REQ, unknown>({
    requestOptions: {
      method: "delete",
      path: "/contractor",
      apiType: "ShipdaDefaultNew",
    },
    ...sideEffectOptions,

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => {
        return {
          title: t("partner:API_업체_삭제_오류"),
        };
      },
    },
  });

  return { ...mutation };
}

function useCheckContractorDuplicate() {
  const mutation = useAppMutation<
    CHECK_CONTRACTOR_DUPLICATE_REQ,
    CHECK_CONTRACTOR_DUPLICATE_RES
  >({
    requestOptions: {
      method: "post",
      path: "/contractor/validate",
      apiType: "ShipdaDefaultNew",
    },
  });

  return { ...mutation };
}

/**
 * 견적 파트너 선택 API (#13075)
 * @param shipmentId - 의뢰 id
 * @param quoteLogId - 견적요청 로그 id
 */
function useSelectQuotationPartner({
  shipmentId,
  quoteLogId,
}: {
  shipmentId: number;
  quoteLogId: number;
}) {
  const mutation = useAppMutation<
    SELECT_QUOTATION_PARTNER_REQ,
    SELECT_QUOTATION_PARTNER_RES
  >({
    requestOptions: {
      method: "put",
      path: `/partner/shipment/${shipmentId}/check/${quoteLogId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

const PARTNER_QUERY = {
  useGetPartnerList,
  useGetPartnerDetail,
  useCreateUserPartner,
  useUpdatePartnerDetail,
  useDeletePartnerManager,
  useGetUserPartnerList,
  useDeleteUserPartner,
  useCheckContractorDuplicate,
  useSelectQuotationPartner,
};

export default PARTNER_QUERY;
