import { APP_NAME } from "../constants";
import {
  ShipdaCurrentLanguage,
  ShipdaCurrentLanguageType,
} from "../i18n/i18nForShipda";

/**
 * 구글 맵 라이브러리를 초기화 할때 공통으로 사용하는 옵션.
 * - 이후에도 초기화할때도 동일한 설정을 사용해야함(어떤 컴포넌트에서 초기화 될 지 알 수 없으므로)
 */

const getGoogleMapCommonBootOption = (): {
  apiKey: string;
  libraries: (
    | "drawing"
    | "geometry"
    | "localContext"
    | "marker"
    | "places"
    | "visualization"
  )[];
  language: ShipdaCurrentLanguageType;
  region: string;
} => ({
  apiKey:
    APP_NAME === "shipda-admin"
      ? process.env.REACT_APP_PUBLIC_GOOGLE_MAP_API_KEY!
      : process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY!,
  libraries: ["places", "marker"],
  language: ShipdaCurrentLanguage.currentLanguage,
  region: "KR",
});

/**
 * 구글 맵의 항적이나 배 위치 등 디자인을 변경할 때 사용하는 테스트 데이터
 */
const GOOGLE_MAP_TEST_DATA = {
  pin: { lat: 33.9282, lng: 131.1662, heading: 0 },
  routes: [
    [
      { lat: 34.5298, lng: 133.739 },
      { lat: 34.4804, lng: 133.7299 },
      { lat: 34.4633, lng: 133.7239 },
      { lat: 34.4503, lng: 133.7131 },
      { lat: 34.4413, lng: 133.6974 },
      { lat: 34.2981, lng: 133.3091 },
      { lat: 34.2914, lng: 133.2908 },
      { lat: 34.2848, lng: 133.2725 },
      { lat: 34.2782, lng: 133.2541 },
      { lat: 34.2487, lng: 133.1704 },
      { lat: 34.2405, lng: 133.1471 },
      { lat: 34.2325, lng: 133.1237 },
      { lat: 34.2246, lng: 133.1003 },
      { lat: 34.1519, lng: 132.8821 },
      { lat: 34.1439, lng: 132.8624 },
      { lat: 34.1335, lng: 132.844 },
      { lat: 34.1208, lng: 132.8269 },
      { lat: 33.8151, lng: 132.471 },
      { lat: 33.8065, lng: 132.4605 },
      { lat: 33.7983, lng: 132.4497 },
      { lat: 33.7906, lng: 132.4385 },
      { lat: 33.7226, lng: 132.3349 },
      { lat: 33.716, lng: 132.3232 },
      { lat: 33.711, lng: 132.3109 },
      { lat: 33.7075, lng: 132.2979 },
      { lat: 33.6775, lng: 132.1407 },
      { lat: 33.6755, lng: 132.1241 },
      { lat: 33.6761, lng: 132.1076 },
      { lat: 33.6792, lng: 132.0911 },
      { lat: 33.9227, lng: 131.186 },
      { lat: 33.9282, lng: 131.1662 },
      { lat: 33.9339, lng: 131.1466 },
      { lat: 33.9398, lng: 131.127 },
      { lat: 33.9643, lng: 131.0489 },
      { lat: 33.9689, lng: 131.0273 },
      { lat: 33.9695, lng: 131.0056 },
      { lat: 33.9658, lng: 130.9838 },
      { lat: 33.9709, lng: 131.0029 },
      { lat: 33.9667, lng: 130.9902 },
      { lat: 33.9612, lng: 130.9781 },
      { lat: 33.9542, lng: 130.9667 },
      { lat: 33.954, lng: 130.9664 },
      { lat: 33.9459, lng: 130.9557 },
      { lat: 33.937, lng: 130.9457 },
      { lat: 33.9272, lng: 130.9365 },
      { lat: 33.9263, lng: 130.9357 },
      { lat: 33.9181, lng: 130.9272 },
      { lat: 33.9119, lng: 130.9175 },
      { lat: 33.9075, lng: 130.9066 },
      { lat: 33.9125, lng: 130.9237 },
      { lat: 33.9106, lng: 130.9123 },
      { lat: 33.9115, lng: 130.9011 },
      { lat: 33.9154, lng: 130.8902 },
      { lat: 33.9095, lng: 130.9021 },
      { lat: 33.9172, lng: 130.8877 },
      { lat: 33.9257, lng: 130.8739 },
      { lat: 33.9351, lng: 130.8605 },
      { lat: 34.0248, lng: 130.7414 },
      { lat: 34.0351, lng: 130.7289 },
      { lat: 34.0465, lng: 130.7174 },
      { lat: 34.0589, lng: 130.7068 },
      { lat: 35.3787, lng: 129.6855 },
      { lat: 35.3888, lng: 129.674 },
      { lat: 35.3911, lng: 129.6612 },
      { lat: 35.3854, lng: 129.6471 },
      { lat: 35.2947, lng: 129.5099 },
      { lat: 35.2888, lng: 129.4957 },
      { lat: 35.2913, lng: 129.4832 },
      { lat: 35.3019, lng: 129.4724 },
      { lat: 35.53722, lng: 129.31667 },
      { lat: 35.5372, lng: 129.3167 },
    ],
  ],
  startPort: {
    id: 385,
    code: "JPMIZ",
    name: "미즈시마항",
    nameC: null,
    nameEN: "Mizushima port",
    lat: 34.5298,
    lng: 133.73896,
  },
  endPort: {
    id: 4,
    code: "KRUSN",
    name: "울산항",
    nameC: null,
    nameEN: "Ulsan port",
    lat: 35.53333333333333,
    lng: 129.31666666666666,
  },
  lat: 33.9282,
  lng: 131.1662,
  heading: 0,
};

export { getGoogleMapCommonBootOption, GOOGLE_MAP_TEST_DATA };
